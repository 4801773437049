<template>
  <!-- "relative" to place Footer -->
  <div style="position: relative">
    <left-menu />
    <router-view :key="$route.path"></router-view>
    <Footer v-if="!ipad && !fullscreen" />
  </div>
</template>

<script>
import LeftMenu from "./LeftMenu";
import Footer from "./Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "HomePage",

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Home",
  },

  components: {
    LeftMenu,
    Footer,
  },

  data() {
    return {
      ipad: navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/),
    };
  },

  computed: {
    ...mapGetters({
      fullscreen: "getFullscreen",
    }),
  },
};
</script>

<style lang="less" scoped></style>
