var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigation-view-container"},[_c('div',{staticClass:"navigation-view-paneParent"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideMenu),expression:"hideMenu"}],staticClass:"navigation-view-pane",class:[_vm.shrinkLeftMenu ? 'shrink' : ''],style:({ height: _vm.leftMenuHeight })},[_c('div',{staticClass:"navigation-view-paneTop"},[_c('div',{staticClass:"navigation-view-topIcon"},[(!_vm.share)?[_c('span',{class:_vm.mobile
                  ? _vm.android
                    ? 'iconFont mobile android'
                    : 'iconFont mobile'
                  : 'iconFont',on:{"click":_vm.shrinkMenu}},[_c('i',{staticClass:"cfa cfa-bars"})]),_c('div',{class:_vm.mobile
                  ? _vm.android
                    ? 'navigation-view-pageTitle mobile android'
                    : 'navigation-view-pageTitle mobile'
                  : 'navigation-view-pageTitle',on:{"click":_vm.goHome}},[_vm._v("  "+_vm._s(_vm.$t("00003"))+" ")])]:[_c('div',{class:_vm.mobile
                  ? 'navigation-view-pageTitle mobile'
                  : 'navigation-view-pageTitle',staticStyle:{"text-align":"center"}},[_vm._v("  "+_vm._s(_vm.$t("00003"))+" "+_vm._s(`${_vm.share ? `${_vm.platform} APP` : ""}`)+" ")])]],2),_c('div',{staticClass:"navigation-view-paneTopIcons",class:[_vm.shrinkLeftMenu ? 'shrink' : '']},[_c('div',{staticClass:"search-container",class:[_vm.shrinkLeftMenu ? 'shrink' : '']},[_c('form',{attrs:{"action":"/"}},[_c('van-search',{attrs:{"shape":"round","clearable":true,"background":"transparent","placeholder":_vm.$t('00000')},on:{"search":_vm.onSearch,"clear":_vm.onClearSearch,"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),(_vm.searchWarning)?_c('div',{staticClass:"no-search-result"},[_vm._v(" Sorry! No search result. ")]):_vm._e()]),_c('scroll-div',{attrs:{"height":"calc(100vh - 155px)","width":"280px","useNative":false,"barStyle":{
              backgroundColor: '#73777B',
            }}},[(_vm.hasSearchResult)?_vm._l((_vm.searchResult),function(item){return _c('search-item',{key:item.Painting_Index,ref:"leftMenuItem",refInFor:true,attrs:{"paintingInfo":item,"shrinkLeftMenu":_vm.shrinkLeftMenu}})}):(_vm.showCollection)?[(!_vm.hasCollectionResult)?_c('div',{staticClass:"loading-svg"},[_c('img',{attrs:{"src":'/img/icons/loading.svg'}})]):_vm._e(),_vm._l((_vm.collectionResult),function(item){return _c('collection-item',{key:item.Painting_Index,ref:"leftMenuItem",refInFor:true,attrs:{"paintingInfo":item,"shrinkLeftMenu":_vm.shrinkLeftMenu}})})]:_vm._l((_vm.genreData),function(item){return _c('left-menu-item',{key:item,ref:"leftMenuItem",refInFor:true,attrs:{"textString":item,"shrinkLeftMenu":_vm.shrinkLeftMenu,"customIcon":_vm.genre === item ? 'fa fa-star' : 'fal fa-star'}})})],2)],1)]),(!_vm.mobile && !_vm.ipad)?_c('div',{staticClass:"navigation-view-paneTopIcons flex-layout",class:[_vm.shrinkLeftMenu ? 'shrink' : '']},[_c('left-menu-item',{attrs:{"customStyle":"width: 100%","textString":_vm.leftMenuItemTextString,"shrinkLeftMenu":_vm.shrinkLeftMenu,"customIcon":"fas fa-user-alt"}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }