<template>
  <span
    class="collection-result-item-container"
    :class="[shrinkLeftMenu ? 'shrink' : '']"
    ><span class="block" ref="leftMenuItemContainerBlock">
      <div class="split-view-command" @click.stop="clickAction()">
        <span><i class="iconFont fas fa-palette"></i></span>
        <div
          class="split-view-command-label"
          :class="[shrinkLeftMenu ? 'shrink' : '']"
        >
          <div class="painting">
            {{ paintingInfo.Name_En || "" }}
          </div>
          <div class="author">{{ `- ${paintingInfo.Painter_En || ""}` }}</div>
        </div>
      </div>
    </span></span
  >
</template>

<script>
import { get } from "lodash";
import { mapActions } from "vuex";

export default {
  name: "CollectionItem",

  props: {
    shrinkLeftMenu: {
      type: Boolean,
      default: false,
    },
    paintingInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    ...mapActions(["updateActiveSinglePainting"]),

    clickAction() {
      this.updateActiveSinglePainting(this.paintingInfo);
      this.$router.push(
        `/painting/${get(this.paintingInfo, "Painting_Index", "")}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.collection-result-item-container {
  display: block;
  width: ~"calc(100% - 9px)";
  //vertical-align: middle;
  overflow: hidden;
  border: 2px solid transparent;
  &.shrink {
    position: relative;
    left: -280px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.25);
  }
  &:hover,
  &:active {
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
  .block {
    // height: 48px;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    .split-view-command {
      flex: 0 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      border-style: solid;
      border-color: transparent;
      border-image: initial;
      border-width: 2px 0px;
      background: none;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      cursor: pointer;
      .iconFont {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        font-family: "Font Awesome 5 Free";
        font-size: 16px;
        transition: all 0.25s ease 0s;
        border: none;
        outline: none;
        user-select: none;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: rgb(255, 255, 255);
        cursor: default;
        flex: 0 0 auto;
      }
      .split-view-command-label {
        padding: 4px 8px 4px 0;
        user-select: none;
        color: rgb(255, 255, 255);
        opacity: 1;
        transition: opacity 0.35s ease-in 0s;
        display: flex;
        flex-direction: column;
        gap: 4px;
        &.shrink {
          opacity: 0;
        }
        .author {
          color: #7d8b91;
          font-size: 12px;
          font-style: italic;
        }
      }
    }
  }
}
</style>